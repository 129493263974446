import React, { useEffect, useContext } from "react"
import LayoutContext from "../components/layout/layout.context"
import HistoriesContext from "../context/histories.context"
import useHistoriesData from "../hooks/useHistoriesData"
import Home from "../components/template/home/Home"

const IndexPage = () => {
  const context = useContext(LayoutContext)
  const historiesData = useHistoriesData()

  useEffect(() => {
    context.setActiveCategory(1)
    context.setIsHome(true)
    return () => {
      context.setActiveCategory(undefined)
      context.setIsHome(undefined)
    }
  }, [])

  return (
    <HistoriesContext.Provider value={historiesData}>
      <div className="wrapper">
        <Home />
      </div>
    </HistoriesContext.Provider>
  )
}

export default IndexPage
